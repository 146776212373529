@keyframes pulse {
  from,
  to {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(0.98);
    opacity: .2;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

*, *::before, *::after {
  box-sizing: inherit;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 15px;
}

body {
  color: #4d4d4d;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

p {
  margin-bottom: .5rem;
  font-size: inherit;
  line-height: 1.3;
}

ul {
  font-size: inherit;
}

a {
  transition: color .1s ease-in-out;
  color: #4cbac0;
}

a:hover,
a:focus,
a:active {
  color: #6fc7cc;
}

h1,
h2,
h3 {
  color: #666;
  font-weight: normal;
}

h1 {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

@media screen and (min-width: 40.063em) {
  h1 {
    font-size: 2.3rem;
  }
}

h2 {
  font-size: 1.6rem;
}

@media screen and (min-width: 40.063em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.2rem;
}

.layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background: #f2f2f0;
}

.layout__content {
  position: absolute;
  top: 3.3886408256rem;
  right: 0;
  left: 0;
  padding: 0;
  transition: all .3s ease-in-out;
}

.restricted {
  max-width: 75.9987860952rem;
}

.restricted--centered {
  margin-right: auto;
  margin-left: auto;
}

.columns {
  padding: 0 1.2944rem;
}

.small-12 {
  width: 100%;
  position: relative;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  float: left;
}

.spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("/images/spinner.gif");
  background-repeat: none;
  background-size: cover;
  user-select: none;
  vertical-align: middle;
}

.spinner::before, .spinner::after {
  display: none;
}

.cssanimations .spinner {
  animation: spin .7s linear;
  border: 5px solid #cccbcb;
  border-radius: 100%;
  border-top-color: transparent;
  background: none;
  animation-iteration-count: infinite;
}

.spinner.spinner--small, #nprogress .spinner {
  width: 1rem;
  height: 1rem;
}

.cssanimations .spinner.spinner--small, .cssanimations #nprogress .spinner, #nprogress .cssanimations .spinner {
  border-width: 3px;
}

.dragonspinner {
  animation: pulse 2s ease-in-out infinite;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;
}

.no-cssanimations .dragonspinner {
  display: none;
}

.button {
  display: inline-block;
  position: relative;
  margin-right: .8rem;
  margin-bottom: 1rem;
  padding: .8rem 1.6rem .8666666667rem;
  transition: all .1s ease-in-out;
  border: 0;
  border-radius: 3px;
  background-color: #f2f2f0;
  color: #4d4d4d;
  font-size: 1rem;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.button:last-of-type {
  margin-right: 0;
}

.button--primary {
  border: 0;
  background-color: #4cbac0;
  color: #fff;
  text-transform: uppercase;
}

.button--disabled, .button.has-spinner,
.button.is-disabled {
  cursor: default;
  opacity: .7;
}

.button--large {
  padding: 1rem 2rem 1.0666666667rem;
  font-size: 1rem;
}

.button:hover {
  background-color: #e1e1dd;
  color: #4d4d4d;
}

.button--primary:hover {
  background-color: #6fc7cc;
  color: #fff;
}

.button.has-spinner .spinner {
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  margin-left: -1rem;
  border-color: #cccbcb;
  border-top-color: transparent;
}

.cssanimations .button.has-spinner .spinner {
  border-width: 3px;
}

.button.button--primary.button--disabled, .button.button--primary.has-spinner {
  background-color: #4cbac0;
}

.button.button--primary.button--disabled.has-spinner, .button.button--primary.has-spinner {
  background-color: #4cbac0;
}

.button.button--primary.button--disabled.has-spinner .spinner, .button.button--primary.has-spinner .spinner {
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  margin-left: -1rem;
  border-color: #fff;
  border-top-color: transparent;
}

.cssanimations .button.button--primary.button--disabled.has-spinner .spinner, .cssanimations .button.button--primary.has-spinner .spinner {
  border-width: 3px;
}

.button.button--primary.has-spinner.is-disabled {
  background-color: #4cbac0;
}

.textbutton,
a.textbutton {
  display: inline-block;
  position: relative;
  margin-right: .8rem;
  margin-bottom: .8rem;
  padding-right: .8rem;
  padding-left: .8rem;
  transition: all .1s ease-in-out;
  border: 0;
  background: none;
  color: #4cbac0;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.textbutton:hover,
a.textbutton:hover {
  color: #6fc7cc;
}

.textbutton:last-of-type,
a.textbutton:last-of-type {
  margin-right: 0;
}

.textbutton.has-spinner,
a.textbutton.has-spinner {
  padding-left: 2.6rem;
  transition: padding .1s ease-out;
}

.textbutton .spinner,
a.textbutton .spinner {
  width: 1rem;
  height: 1rem;
  margin-right: .2rem;
  margin-left: -1rem;
}

.cssanimations .textbutton .spinner, .cssanimations
a.textbutton .spinner {
  border-width: 3px;
}

.textbutton.textbutton--primary {
  color: #4cbac0;
}

.textbutton.textbutton--primary:hover {
  color: #6fc7cc;
}

.textbutton.textbutton--front-page {
  color: #6fc7cc;
}

.textbutton.textbutton--front-page:hover {
  color: #6fc7cc;
}

.actions {
  margin: 2rem 0 1rem;
}

.actions:empty {
  margin: 0;
}

@media screen and (max-width: 40em) {
  .actions .button,
  .actions .splitbutton,
  .actions .textbutton {
    display: block;
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (min-width: 40.063em) {
  .actions .button,
  .actions .splitbutton,
  .actions .textbutton {
    margin-bottom: 0;
  }
}

.icon {
  display: inline-block;
  width: 3.3886408256rem;
  max-width: 100%;
  height: 3.3886408256rem;
  max-height: 100%;
  vertical-align: middle;
}

.icon--micro {
  width: 0.8rem;
  height: 0.8rem;
}

.icon--tiny {
  width: 1rem;
  height: 1rem;
}

.icon--small {
  width: 1.2944rem;
  height: 1.2944rem;
}

.icon--medium {
  width: 2.0943392rem;
  height: 2.0943392rem;
}

.icon--large {
  width: 3.3886408256rem;
  height: 3.3886408256rem;
}

.icon--jumbo {
  width: 5.4828208558rem;
  height: 5.4828208558rem;
}

.document {
  display: block;
  position: relative;
  margin-bottom: 1rem;
  padding: 1.2944rem 2.0943392rem;
  background: #fff;
  box-shadow: 1px 1px 1px rgba(71, 71, 62, 0.1);
}

.document::before {
  height: 0;
  width: 0;
  border-bottom: 2.0943392rem solid #f7cf80;
  border-left: 2.0943392rem solid #f2f2f0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.document__header {
  margin-bottom: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #cccbcb;
}

.document__headertools {
  float: left;
}

@media screen and (min-width: 40.063em) {
  .document__headertools {
    float: right;
  }
}

.document__headerlogo {
  float: left;
}

.document-preview__page {
  box-sizing: content-box;
}

.document--chopped::after {
  display: block;
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 1rem;
  background-image: linear-gradient(45deg, transparent 1rem, #fff 0), linear-gradient(-45deg, transparent 1rem, #fff 0), linear-gradient(-45deg, transparent 14px, rgba(71, 71, 62, 0.1) 0);
  background-repeat: repeat-x;
  background-position: center bottom;
  background-size: 1rem 1rem;
  content: '';
}

label {
  display: block;
  margin-bottom: .6180469716rem;
  color: #999;
  font-size: 13px;
  line-height: 1.5;
  cursor: pointer;
}

input:not([type='checkbox']):not([type='radio']),
select,
textarea {
  transition: all .15s linear, background .15s linear;
  border: 1px solid #cccbcb;
  outline: none;
  background-color: #fff;
  color: #47473e;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  box-shadow: none;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input [type='checkbox'] {
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox;
  box-sizing: border-box;
}

input [type='radio'] {
  -moz-appearance: radio;
  -webkit-appearance: radio;
  box-sizing: border-box;
}

select {
  border: 1px solid #f2f2f0;
  background-color: #f2f2f0;
}

select:hover {
  border-color: #f2f2f0;
  background-color: #f7f7f7;
}

::-webkit-input-placeholder {
  opacity: .4;
}

::-moz-placeholder {
  /* Firefox 18- */
  opacity: .4;
}

:-ms-input-placeholder {
  opacity: .4;
}

:focus::-webkit-input-placeholder {
  -webkit-transition: opacity .55s ease-in-out;
  transition: opacity .55s ease-in-out;
  opacity: 0;
}

:focus::-moz-placeholder {
  -moz-transition: opacity .55s ease-in-out;
  transition: opacity .55s ease-in-out;
  opacity: 0;
}

:focus:-ms-input-placeholder {
  -ms-transition: opacity .55s ease-in-out;
  transition: opacity .55s ease-in-out;
  opacity: 0;
}

input:focus {
  border-color: #f2f2f0;
}

input.has-error,
textarea.has-error,
select.has-error {
  margin-bottom: 0;
  transition: margin-bottom 0;
  border-color: #de5b6b !important;
}

input.has-success,
textarea.has-success,
select.has-success {
  border-color: #4cbac0 !important;
}

input.form__element,
select.form__element,
.form__element {
  display: block;
  width: 100%;
  height: 2.5333333333rem;
  margin: 0 0 1.0666666667rem;
  padding: .5333333333rem;
  border: 1px solid #cccbcb;
  border-radius: 2px;
  background-color: #fff;
}

input.form__element--big,
select.form__element--big {
  height: 3.3886408256rem;
  padding: .8rem;
  font-size: 1.2944rem;
}

input.form__element:focus,
select.form__element:focus {
  border-color: #80807f;
}

input.form__element--ghost,
.form__element--ghost {
  border: 0;
  background: none;
}

input.form__element--ghost:focus,
.form__element--ghost:focus {
  background: none;
}

.form__errorlist {
  margin: 0 0 1rem;
  padding: 0;
  transition: all .1s ease-in-out;
  color: #de5b6b;
  font-size: .8rem;
  list-style: none;
  opacity: 0;
}

.form__errorlist.filled {
  padding-top: 6px;
  opacity: 1;
}

input.form__element--pin-single {
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #a09f9f;
  border-left: 0;
  font-size: .8rem;
  text-align: center;
}

@media only screen and (min-width: 40.063em) {
  input.form__element--pin-single {
    height: 3.3886408256rem;
    font-size: 2.617924rem;
  }
}

input.form__element--pin-single.has-error {
  margin-bottom: 1rem;
}

input.form__element--pin-single.has-error + .form__errorlist.filled {
  display: none;
}

.logo {
  display: block;
  max-width: 100%;
  height: auto;
  clear: both;
  vertical-align: middle;
}

.logo--front-page,
.logo--front-page img {
  max-width: 37.5766556713rem;
  margin: 0 auto;
}

.logo--front-page img {
  display: block;
  max-height: 8.8712041447rem;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 10;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  position: fixed;
  top: 15px;
  right: 15px;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

.error {
  position: relative;
  top: 50%;
  transform: translateY(50%);
}

.error__header {
  margin: .5em 0;
  color: #a09f9f;
  font-family: 'Helvetica Neue', Roboto, Arial, sans-serif;
}

@media only screen and (max-width: 40em) {
  .error__header {
    font-weight: bold;
  }
}

.error__button {
  display: inline-block;
  margin: 1.2944rem 1rem;
  padding: .8rem 1rem;
  transition: background 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border-radius: 3px;
  background: #de5b6b;
  color: #fff;
  font-family: 'Helvetica Neue', Roboto, Arial, sans-serif;
  text-align: center;
  cursor: pointer;
}

.error__button:hover {
  background: #e47986;
}

.error__text {
  display: none;
}

@media only screen and (min-width: 40.063em) {
  .error__text {
    display: inline-block;
  }
}

.error__link {
  display: inline-block;
  margin: 1.2944rem 1rem;
  color: #de5b6b;
  font-family: 'Helvetica Neue', Roboto, Arial, sans-serif;
}

.error__link:hover,
.error__link:active,
.error__link:focus {
  color: #e47986;
}

.u-pos__vcenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.u-text__center {
  text-align: center;
}

.is-gone {
  display: none;
}

meta.foundation-version {
  font-family: '/5.5.2/';
}

meta.foundation-mq-small {
  width: 0;
  font-family: '/only screen/';
}

meta.foundation-mq-small-only {
  width: 0;
  font-family: '/only screen and (max-width: 40em)/';
}

meta.foundation-mq-medium {
  width: 40.063em;
  font-family: '/only screen and (min-width:40.063em)/';
}

meta.foundation-mq-medium-only {
  width: 40.063em;
  font-family: '/only screen and (min-width:40.063em) and (max-width:64em)/';
}

meta.foundation-mq-large {
  width: 64.063em;
  font-family: '/only screen and (min-width:64.063em)/';
}

meta.foundation-mq-large-only {
  width: 64.063em;
  font-family: '/only screen and (min-width:64.063em) and (max-width:90em)/';
}

meta.foundation-mq-xlarge {
  width: 90.063em;
  font-family: '/only screen and (min-width:90.063em)/';
}

meta.foundation-mq-xlarge-only {
  width: 90.063em;
  font-family: '/only screen and (min-width:90.063em) and (max-width:120em)/';
}

meta.foundation-mq-xxlarge {
  width: 120.063em;
  font-family: '/only screen and (min-width:120.063em)/';
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

.ie-warning-card {
  display: none;
}

.no-textshadow .ie-warning-card {
  display: block;
  max-width: 80%;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 20px 50px;
  background-color: #fff;
  font-family: Arial, 'Helvetica Neue', sans-serif;
  text-align: center;
}

.dragonlaw .dragonspinner {
  width: 5.489rem;
  height: 3.39rem;
  background-image: url("../images/icon-dragonlaw--mono.svg");
}

.dragonlaw #nprogress .bar {
  background: #de5b6b;
}

.dragonlaw #nprogress .peg {
  box-shadow: 0 0 10px #de5b6b, 0 0 5px #de5b6b;
}

.row {
  width: 1240px;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.row.row--outer {
  padding: 0 1rem;
}

@media only screen and (min-width: 90.063em) {
  .row.row--outer {
    padding: 0;
  }
}

.row.restricted {
  width: 75.9987860952rem;
  max-width: 100%;
}

.box__mb1 {
  margin-bottom: 1rem !important;
}
